import './Blueprints.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { useOpenApi, checkResult } from '../../hooks/api';
import BlueprintImage from '../blueprint-image/BlueprintImage';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useSetError } from '../../hooks/error';
import { useGetApi } from '../../hooks/api';

export async function useLoader({ params }) {
    const id = params.id;
    const api = useOpenApi();
    const result = await api.get(`/blueprints/public/${id}`);
    checkResult(result);
    return await result.json();
}

export default function BlueprintsInfo() {
    const [blueprint, setBlueprint] = useState(useLoaderData());
    const navigate = useNavigate();

    const notAllProcessed = useMemo(() => blueprint.blueprints.filter(b => !b.processed).length > 0, [blueprint.blueprints]);
    const api = useOpenApi();
    useEffect(() => {
        if(!notAllProcessed) {
            return;
        }
        const interval = setInterval(() => {
            api.get(`/blueprints/public/${blueprint.id}`).then(result => {
                checkResult(result);
                result.json().then(b => {
                    setBlueprint(b);
                })
            })

        }, 1000);

        return () => clearInterval(interval);
    }, [api, blueprint.id, notAllProcessed]);

    const searchParams = useSearchParams()[0];
    const profileParam = searchParams.get('profile');

    const [likesCount, setLikesCount] = useState(blueprint.likes);
    const [likeLoading, setLikeLoading] = useState(false);
    const getApi = useGetApi();
    const setError = useSetError();
    const apiHere = useMemo(() => !!getApi(), [getApi]);

    const like = useCallback((id) => {
        const api = getApi();
        if(!api) {
            return;
        }
        setLikeLoading(true);
        api.patch(`/blueprints/${id}/like`)
            .then(result => {
                if(result.ok) {
                    result.text().then(data => {
                        setLikesCount(+data);
                    });
                    setLikeLoading(false);
                } else {
                    setError("Failed to like blueprint");
                    setLikeLoading(false);
                }
            })
            .catch(err => {
                setError("Failed to like blueprint");
                setLikeLoading(false);
            });

    }, [getApi, setError])


    return (
        <Container style={{minHeight: '80vh'}} className="p-2">
            <Row className="align-items-center">
                <Col xs={2}>
                   <BlueprintImage small image={blueprint.preview} processed={!!blueprint.preview} />
                </Col>
                <Col xs={7}>
                    <h5>{blueprint.title} by {blueprint.creator}</h5>
                    {/* <span>downloads {blueprint.downloads}</span><br /> */}
                    <span>created <ReactTimeAgo date={new Date(blueprint.created)} locale="en-US"/></span><br />
                    <span>updated <ReactTimeAgo date={new Date(blueprint.updated)} locale="en-US"/></span><br />
                </Col>
                <Col xs={3}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                        <Button href={blueprint.downloadUrl}>Download</Button>
                        <Button style={{marginLeft: '1rem'}} variant='outline-primary' disabled={!apiHere || likeLoading} onClick={() => like(blueprint.id)}>Likes: {likesCount}</Button>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: '1rem'}}>
                <Col>
                    <h3>Blueprints:</h3>
                    <p>{blueprint.description}</p>
                </Col>
            </Row>
            <Row>
                {blueprint && blueprint.blueprints?.map(it => {
                    return (
                        <Col xs={3}>
                            <BlueprintImage image={it.image} processed={it.processed} />
                            <p style={{marginLeft: '0.5rem'}}>{it.title}</p>
                        </Col>
                    )
                })}
            </Row>
            <div className="bottom-container">
                <Button href={blueprint.downloadUrl}>Download</Button>
                <Button onClick={() => navigate(profileParam?'/profile':-1)} variant="outline-secondary">{profileParam? "To profile" : "Back"}</Button>
            </div>
        </Container>
    )
}