import { Row, Nav, Button } from "react-bootstrap";
import { joinPatreonLink, websiteLink, contacts, privacyPolicy, cookiePolicy } from "../../config/infoLinksConfig";


export default function Footer() {
    return (
        <Row className="p-3">
            <Nav className="justify-content-center align-items-center">
                <Nav.Link href={websiteLink}>Mod Website</Nav.Link>
                <Button href={joinPatreonLink} variant="outline-primary">Become a Patron</Button>
                <Nav.Link href={contacts}>Contacts</Nav.Link>
                <Nav.Link href={privacyPolicy}>Privacy policy</Nav.Link>
                <Nav.Link href={cookiePolicy}>Cookie Policy</Nav.Link>
            </Nav>
        </Row>
    )
}