export const msalConfig = {
    auth: {
      clientId: "52327bbf-e3cc-4fba-a381-53edec9daa46",
      authority: "https://login.microsoftonline.com/consumers", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: process.env.NODE_ENV === "development" ? "http://localhost:3000" : process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: [
        "api://52327bbf-e3cc-4fba-a381-53edec9daa46/access_as_user",
    ],
    extraScopesToConsent: [
        "XboxLive.signin"
    ]
};

export const tokenRequest = {
    scopes: [
        "api://52327bbf-e3cc-4fba-a381-53edec9daa46/access_as_user",
    ]
};