import './Dropzone.css';
import React, {useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

export default function Dropzone({onFileSelected = () => {}}) {
    const [error, setError] = React.useState(null);

    const onDrop = (acceptedFiles) => {
        const filterdFiles = acceptedFiles.filter(it => it.name.endsWith('.blueprints'));;
        if(filterdFiles.length > 0) {
            onFileSelected(acceptedFiles[0]);
        }else {
            setError('Only .BLUEPRINTS files are allowed');
        }
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
    });

    useEffect(() => {
        if(isDragActive) setError(null);
    }, [isDragActive]);

    return (
        <div className='app-dropzone' {...getRootProps()}>
          <input {...getInputProps()} />
          {error && <p className='app-dropzone-error'>{error}</p>}
          {isDragActive ? <p>Drop here to upload</p> : <p>Drag and drop .blueprints file here to upload</p>}
        </div>
    )

}