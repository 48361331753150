import { useCallback } from "react";
import { useToken } from "./auth";
const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8080";
export function useGetApi() {
    const token = useToken();

    return useCallback(() => {
        if(!token) return null;

        return {
            get: async (path) => {
                if(!token || token.length === 0) {
                    throw new Error("User is not authorized properly");
                }
    
                return fetch(apiUrl + path, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            },
            postFile: async (path, file) => {
                if(!token || token.length === 0) {
                    throw new Error("User is not authorized properly");
                }
    
                const formData = new FormData();
                formData.append("file", file);
    
                return fetch(apiUrl + path, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    body: formData
                });
            },
            put: async (path, data) => {
                if(!token || token.length === 0) {
                    throw new Error("User is not authorized properly");
                }
    
                return fetch(apiUrl + path, {
                    method: "PUT",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });
            },
            patch: async (path, data = null) => {
                if(!token || token.length === 0) {
                    throw new Error("User is not authorized properly");
                }

                return fetch(apiUrl + path, {
                    method: "PATCH",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    },
                    body: data != null ? JSON.stringify(data) : null
                });
            },
            delete: async (path) => {
                if(!token || token.length === 0) {
                    throw new Error("User is not authorized properly");
                }
    
                return fetch(apiUrl + path, {
                    method: "DELETE",
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
            }
        }
    }, [token])
    
}

export function useOpenApi() {
    const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8080";
    return {
        get: async (path) => {
            return fetch(apiUrl + path);
        }
    }
}

export function checkResult(result) {
    const firstDigitOfCode = Math.floor(result.status / 100);
    if(firstDigitOfCode === 4 || firstDigitOfCode === 5) {
        throw result;
    }
}