import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import './App.css';
import Navigation from './components/navigation/Navigation';
import Footer from './components/footer/Footer';
import {Outlet} from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './config/authConf';
import ErrorProvider, {ErrorAlert} from './hooks/error';
import CookieConsent from 'react-cookie-consent';
import {cookiePolicy} from './config/infoLinksConfig';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  return (
    <ErrorProvider>
    <MsalProvider instance={msalInstance}>
      <Container>
        <Navigation />
        <Outlet />
        <Footer />
        <ErrorAlert />
      </Container>
      <CookieConsent>
        This website uses <a href={cookiePolicy}>cookies</a> to enhance the user experience.
      </CookieConsent>
    </MsalProvider>
    </ErrorProvider>
  );
}

export default App;
