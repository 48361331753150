import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import { useNavigate } from 'react-router-dom';
import { useGetApi } from '../../hooks/api';
import { useState, useCallback, useMemo } from 'react';
import { useSetError } from '../../hooks/error';

import en from 'javascript-time-ago/locale/en.json'

TimeAgo.addDefaultLocale(en)

export default function BlueprintsCard({id, title, image, author, updated, likes}) {
    const navigate = useNavigate();
    const getApi = useGetApi();
    const [likesCount, setLikesCount] = useState(likes);
    const [likeLoading, setLikeLoading] = useState(false);
    const setError = useSetError();

    const apiHere = useMemo(() => !!getApi(), [getApi]);

    const like = useCallback((id) => {
        const api = getApi();
        if(!api) {
            return;
        }
        setLikeLoading(true);
        api.patch(`/blueprints/${id}/like`)
            .then(result => {
                if(result.ok) {
                    result.text().then(data => {
                        setLikesCount(+data);
                    });
                    setLikeLoading(false);
                } else {
                    console.error(result);
                    setError("Failed to like blueprint");
                    setLikeLoading(false);
                }
            })
            .catch(err => {
                console.error(err);
                setError("Failed to like blueprint");
                setLikeLoading(false);
            });

    }, [getApi, setError])

    return (
        <Card style={{ width: '15rem', padding: 0, margin: '1rem'}}>
            <Card.Img style={{height: '13rem', objectFit: 'cover',}} variant="top" src={image || "/placeholder.jpg"} />
            <Card.Body>
                <Card.Text style={{marginBottom: 0, lineHeight: 1, height: '2em'}}><b>{title}</b></Card.Text>
                <Card.Text> by {author}</Card.Text>
                <div style={{display: 'flex', flexDirection: 'row', jusitfyContent: 'space-around'}}>
                    <Button onClick={() => navigate("/blueprints/" + id)} variant="primary">Open</Button>
                    <Button disabled={!apiHere || likeLoading} onClick={() => like(id)} style={{marginLeft: 'auto'}} variant="outline-primary">Likes: {likesCount}</Button>
                </div>
            </Card.Body>
            <Card.Footer>updated: <ReactTimeAgo date={new Date(updated).getTime()} locale="en-US"/></Card.Footer>
        </Card>
    )
}