import { Container } from "react-bootstrap"
import { useToken } from "../../hooks/auth"; 

export default function AuthToken() {
    const token = useToken();

    return (
        <Container style={{minHeight: '80vh'}}>
            <textarea readOnly value={token} rows={5} style ={{width: '100%'}} />
        </Container>    
    )
}