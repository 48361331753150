import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { tokenRequest, loginRequest } from "../config/authConf";
import { useEffect, useState } from "react";
import { useSetError } from "./error";

export function useLogin() {
    const { instance } = useMsal();
    const setError = useSetError();
    return () => instance.loginRedirect(loginRequest)
        .catch(e => {
            if(process.env.NODE_ENV === 'development') {
                console.log(e);
            }
            setError('Cannot login!', e && e.message);
        });
}

export function useLogout() {
    const { instance } = useMsal();
    const setError = useSetError();
    return () => instance.logoutRedirect({
        postLogoutRedirectUri: "/"
    }).catch(e => {
        if(process.env.NODE_ENV === 'development') {
            console.log(e);
        }
        setError('Cannot logout!', e && e.message);
    });
}

export function useToken() {
    const { instance, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [ accessToken, setAccessToken ] = useState("");
    const setError = useSetError();
    
    useEffect(() => {
        if(!isAuthenticated || accounts.length === 0) {
            return;
        }
        const request = {
            ...tokenRequest,
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then(
            response => {
                const {accessToken} = response;
                setAccessToken(accessToken);
            }
        ).catch(error => {
            if(process.env.NODE_ENV === 'development') {
                console.log(error);
            }
            setError('Cannot get access token!', error && error.message);
        });
    }, [instance, accounts, isAuthenticated, setAccessToken, setError]);

    return accessToken;
}
