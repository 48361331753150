import {
    Button,
    ButtonGroup,
} from 'react-bootstrap';

export default function PageButtons({currentPage=1, totalPages=1, onPageUpdate = () => {}}) {
    const firstPage = 1;
    const previousPage = currentPage - 1;
    const nextPage = currentPage + 1;
    const lastPage = totalPages;

    const PageButton = ({number}) => {
        return (
            <Button
                variant={currentPage === number ? 'primary' : 'outline-primary'}
                onClick={() => onPageUpdate(number)}
            >
                {number}
            </Button>
        );
    }
    
    return (
        <ButtonGroup  aria-label="pages">
            {currentPage !== firstPage && <PageButton number={firstPage} />}
            {previousPage - 1 > firstPage && <DotsButton />}
            {previousPage > firstPage && <PageButton number={previousPage} />}
            <PageButton number={currentPage} />
            {nextPage < lastPage && <PageButton number={nextPage} />}
            {nextPage + 1 < lastPage && <DotsButton />}
            {currentPage !== lastPage && <PageButton number={lastPage} />}
        </ButtonGroup>
    )
}

function DotsButton() {
    return (
        <Button disabled variant="outline-primary">...</Button>
    )
}