import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Cards, {useLoader as cardsLoader} from './components/cards/Cards';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './components/errorpage/ErrorPage';
import BlueprintsInfo, {useLoader as blueprintsLoader} from './components/blueprints-info/BlueprintsInfo';
import Upload from './components/upload/Upload';
import Edit from './components/edit/Edit';
import Profile from './components/profile/Profile';
import AuthToken from './components/token/AuthToken';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Cards />,
        loader: cardsLoader,
      },
      {
        path: "blueprints/:id",
        element: <BlueprintsInfo />,
        loader: blueprintsLoader,
      },
      {
        path: "upload",
        element: <Upload />,
      },
      {
        path: "edit/:id",
        element: <Edit />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "token",
        element: <AuthToken />,
      }
    ],
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
