import { Container, Row, Button, Col } from "react-bootstrap";
import { useNavigate, useRouteError, isRouteErrorResponse } from "react-router-dom";

export default function ErrorPage() {
    const navigate = useNavigate();
    const error = useRouteError();

    if(process.env.NODE_ENV === 'development' || !isRouteErrorResponse(error)) {
        console.log(error);
    }

    const serverError = error instanceof TypeError && error.message?.startsWith('Failed to fetch');

    return (
        <Container style={{height: '100vh', paddingTop: '10rem'}}>
            <Row xs={1}>
                {
                    isRouteErrorResponse(error) ? (
                        <Col style={{display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
                            <h1 style={{textAlign: 'center'}}>{prepareErrorTitle(error)} 😥</h1>
                            <p style={{textAlign: 'center', marginBottom: '2rem'}}>{error.data?.message}</p>
                            <Button onClick={() => navigate("/")} variant="primary">Browse all blueprints</Button>
                        </Col>
                    ) : (
                        <Col style={{display: 'flex', flexDirection: 'column',  alignItems: 'center'}}>
                            <h1 style={{textAlign: 'center'}}>{serverError ? "API is down 😨" : "Something went wrong 😥"}</h1>
                            <p style={{textAlign: 'center', marginBottom: '0.5rem'}}>{serverError ? "Looks like the server is down! Most probaly I am already aware of that and trying to fix that. Come back later!" : "Unfortunately something went wrong while loading this page..."}</p>
                            {!serverError && <Button onClick={() => navigate("/")} variant="primary">Browse all blueprints</Button>}
                        </Col>
                    )
                }
            </Row>
        </Container>
    )
}

function prepareErrorTitle(error) {
    if(error.statusText) {
        return `${error.statusText} ${error.status}`
    } else {
        switch(error.status) {
            case 400:
                return "Bad request";
            case 401:
                return "Unauthorized (401)";
            case 403:
                return "Forbidden (403)";
            case 404:
                return "Not found";
            case 500:
                return "Internal server error";
            default:
                return "Something went wrong";
        }
    }
}