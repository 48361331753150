import { Navbar, Nav, Row, Button, NavDropdown } from 'react-bootstrap';
import signInWithMicrosoft from '../../assets/login-with-ms.svg';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { useLogin, useLogout } from '../../hooks/auth';
import { useProfile } from '../../hooks/profile';
import { joinPatreonLink, websiteLink } from "../../config/infoLinksConfig";

export default function Navigation() {
    const isAuthenticated = useIsAuthenticated();
    const msLogin = useLogin();
    const msLogout = useLogout();
    const profile = useProfile();

    const navigate = useNavigate();
    return (
        <Row>
            <Navbar>
                <Navbar.Brand href="/">MineFortress - Blueprints</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                <Nav className="align-items-center">
                    <Nav.Link  href={websiteLink}>Mod Website</Nav.Link>
                    <Button href={joinPatreonLink} variant="outline-primary">Become a Patron</Button>
                    { isAuthenticated ? <ProfileDropdown navigate={navigate} onLogout={msLogout} username={profile?.username || "loading profile..."} /> : <SignInMicrosoft onLogin={msLogin} /> }
                </Nav>
                </Navbar.Collapse>
            </Navbar>
      </Row>
    );
}

function SignInMicrosoft({onLogin}) {
    return (
        <Nav.Link onClick={() => onLogin()}>
            <img style={{ 'maxHeight': '50px' }} alt="Sign in with Microsoft" src={signInWithMicrosoft}></img>
        </Nav.Link>
    );
}

function ProfileDropdown({username, navigate, onLogout}) {
    return (
        <NavDropdown title={username} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => navigate("/upload")}>Upload</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate("/profile")}>Profile</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={onLogout}>Sign Out</NavDropdown.Item>
        </NavDropdown>
    );
}
