import './Profile.css';
import {Container, Row, Col, Button, Table, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useEffect, useState, useCallback} from "react";
import {useIsAuthenticated} from '@azure/msal-react';
import { useGetApi } from '../../hooks/api';

export default function Profile() {
    const navigate = useNavigate();

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(true);



    const [profile, setProfile] = useState(null);
    const getApi = useGetApi();

    const profileNotNull = !!profile;

    useEffect(() => {
        const api = getApi();
        console.log("Getting profile");
        if(!api) {
            if(profileNotNull) setProfile(null);
            return;
        }
        api.get("/profile").then(response => {
            const firstCodeNumber = Math.floor(response.status / 100);
            if(firstCodeNumber === 4 || firstCodeNumber === 5) {
                response.json()
                .then(json => setError(json.message || `Error loading profile ${response.status}`))
                .catch(() => setError(`Error loading profile ${response.status}`));
                return;
            }
            response.json().then(p => setProfile(p))
            .catch(e => setError(e.message))
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            setError("Error loading profile");
        });

    }, [getApi, setProfile, profileNotNull]);

    const removeBlueprint = useCallback((id) => {
        setLoading(true);
        const api = getApi();
        if(!api) {
            setLoading(false);
            setError("Error deleting blueprint");
            return;
        }

        api.delete(`/blueprints/${id}`).then(response => {
            const firstCodeNumber = Math.floor(response.status / 100);
            if(firstCodeNumber === 4 || firstCodeNumber === 5) {
                response.json()
                .then(json => setError(json.message || `Error deleting blueprint ${response.status}`))
                .catch(() => setError(`Error deleting blueprint ${response.status}`));
                setLoading(false);
                return;
            }
            setProfile(null);
        }).catch(e => {
            setLoading(false);
            setError("Error deleting blueprint");
        });
    }, [getApi]);

    const ProfileData = useCallback(({username, blueprints}) => {
        return (
            <Row>
                <Col>
                    <h4>{username}'s blueprints</h4>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>image</th>
                                <th>id</th>
                                <th>title</th>
                                {/* <th>downloads</th> */}
                                <th>published</th>
                                <th>edit</th>
                                <th>remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {blueprints.map(({id, image, title, downloads, published}) => (
                                <tr key={id}>
                                    <td><img src={image || '/placeholder.jpg'} alt={title} style={{width: '100px', height: '100px', objectFit: 'cover'}}/></td>
                                    <td>{id}</td>
                                    <td>{title}</td>
                                    {/* <td>this info will be available in the future</td> */}
                                    <td>{published?'true':'false'}</td>
                                    <td><Button onClick={() => navigate("/edit/"+id)}>Edit</Button></td>
                                    <td><Button onClick={() => removeBlueprint(id)}>Remove</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

        )
    }, [navigate, removeBlueprint]);

    return (
        <Container style={{minHeight: '80vh'}}>
            <Row className="align-items-center p-2">
                <Col>
                    <h2>Profile</h2>
                </Col>
                <Col xs={1}>
                    <Button onClick={() => {navigate(-1)}} variant="outline-primary">Back</Button>
                </Col>
                <Col xs={2}>
                    <Button onClick={() => {}} variant="primary">Sign-Out</Button>
                </Col>
            </Row>
            {error && (
                <Row className="align-items-center p-2">
                    <Col>
                        <p className="app-profile-error">{error}</p>
                    </Col>
                </Row>
            )}
            {!loading && profile && <ProfileData {...profile}  />}
            {loading && <Loading />}
            <Row className="align-items-center justify-content-end p-2">
                <Col xs={1}>
                    <Button onClick={() => {navigate(-1)}} variant="outline-primary">Back</Button>
                </Col>
                <Col xs={2}>
                    <Button onClick={() => {}} variant="primary">Sign-Out</Button>
                </Col>
            </Row>
        </Container>

    );
}

function Loading() {
    return (
        <Container style={{minHeight: '40vh'}}>
            <div className="profile-loading">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                <p>Loading...</p>
            </div>
        </Container>
    );
}