import React, { useContext, useState, useCallback } from "react";
import Alert from "react-bootstrap/Alert";

const ErrorContext = React.createContext();

export default function ErrorProvider({ children }) {
    const [error, setError] = useState(null);
    
    return (
        <ErrorContext.Provider value={{ error, setError }}>
            {children}
        </ErrorContext.Provider>
    );
}



export function useSetError() {
    const setError = useContext(ErrorContext).setError
    const [prevTimeout, setPrevTimeout] = useState(null);
    return useCallback((errorTitle, errorDesc = null) => {
        console.log("Setting error: " + errorTitle);
        if (prevTimeout) {
            clearTimeout(prevTimeout);
        }
        if(errorTitle != null) {
            setError({errorTitle, errorDesc});
            setPrevTimeout(
                setTimeout(() => {
                    setError(null);
                    setPrevTimeout(null);
                }, 5000)
            );
        } else {
            setError(null);
        }
    }, [prevTimeout, setError]);
}

function useError() {
    return useContext(ErrorContext).error;
}

// bottom animation
const errorAlertStyle = {
    position: "fixed",
    bottom: "0",
    left: "5%",
    width: "90%",
    margin: "1rem auto",
    zIndex: "1000",
    padding: "0.5rem",
    transition: "all 0.5s ease-in-out",
}

export function ErrorAlert() {
    const error = useError();
    const setError = useSetError();
    return error ? (
        <Alert style ={{...errorAlertStyle, bottom: error ? '0' : '-100'}} variant="danger" onClose={() => setError(null)} dismissible>
            <Alert.Heading>{error.errorTitle}</Alert.Heading>
            <p>{error.errorDesc}</p>
        </Alert>
    ) : null;
}