import './BlueprintImage.css';
import { Image, Spinner } from "react-bootstrap"; 

export default function BlueprintImage({image, processed, small}) {

    if(processed)
        return (
            <Image 
                className={'blueprint-image' + (small ? " blueprint-small":"")} 
                src={image} 
                thumbnail 
            />
        )
    else
        return (
            <div className={'blueprint-processing' + (small ? " blueprint-small":"")}>
                <Spinner animation="border" role="status" />
                <br/>
                <span>Processing image...</span>
            </div>
        )     
}