import './Upload.css';
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useCallback, useState } from "react";
import Dropzone from "./Dropzone";
import { useIsAuthenticated } from '@azure/msal-react';
import { useGetApi } from '../../hooks/api';

export default function Upload() {
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getApi = useGetApi();

    const onFileSelected = useCallback((file) => {
        setLoading(true);
        setError(null);

        const api = getApi();
        if(!api) {
            setError("API client is loading... Try again a bit later");
            setLoading(false);
            return;
        } else {
            api.postFile('/blueprints/upload', file)
                .then(resp => {
                    const statusFirst = Math.floor(resp.status / 100)
                    if(statusFirst === 4 || statusFirst === 5) {
                        setError("Error uploading file");
                        resp.json().then(json => {
                            if(json.message) {
                                setError(json.message);
                            }
                        });
                    } else {
                        resp.json().then(data => {
                            setLoading(false);
                            const { id } = data;
                            if(id) {
                                navigate(`/edit/${data.id}`);
                            } else {
                                setError("Returned blueprints list id is null");
                            }
                        })
                        .catch(err => {
                            setError("Error parsing response");
                            setLoading(false);
                        });
                    }
                })
                .catch(err => {
                    setLoading(false);
                    setError(JSON.stringify(err));
                });
        }
    }, [getApi, navigate]);

    return (
        <Container style={{minHeight: '80vh'}}>
            <Row className="align-items-center p-2">
                <Col>
                    <h2>Upload your Blueprints</h2>
                </Col>
                <Col xs={1}>
                    <Button onClick={() => {navigate(-1)}} variant="outline-primary">Back</Button>
                </Col>
            </Row>
            {error && (
                <Row className="align-items-center p-2">
                    <Col>
                        <p className="app-upload-error">{error}</p>
                    </Col>
                </Row>
            )}
            <Row>
                {!loading && <Dropzone onFileSelected={onFileSelected} />}
                {loading && (
                    <div className="upload-loading">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Uploading...</span>
                        </Spinner>
                    </div>
                )}
            </Row>
        </Container>
    )
}