import { checkResult, useGetApi } from "./api"
import { useState, useEffect } from "react";

export function useProfile() {
    const [profile, setProfile ] = useState(null);

    const getApi = useGetApi();
    

    useEffect(() => {
        const api = getApi();
        if(!api) {
            setProfile(null);
            return;
        }
        api.get("/profile").then(response => {
            checkResult(response);
            response.json().then(setProfile);
        });
    }, [getApi, setProfile]);

    return profile;
}