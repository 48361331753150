import './Edit.css';
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Form, Container, Row, Col, Spinner, Button } from "react-bootstrap";
import { useIsAuthenticated } from '@azure/msal-react';
import { useGetApi } from '../../hooks/api';
import BlueprintImage from '../blueprint-image/BlueprintImage';

export default function Edit() {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const getApi = useGetApi();
    const [info, dispatch] = useReducer(infoReducer, {});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const {id} = useParams();

    const setErrorFromException = (err) => {
        if(err instanceof TypeError)
            setError("Could not connect to the server");
        else 
            setError(err.message || err.statusText || `Error ${err.status}`);
    }

    useEffect(() => {
        const api = getApi();
        if(!api) return;
        (async () => {
            try {
                const resp = await api.get(`/blueprints/${id}`);
                if(Math.floor(resp.status / 100) === 4 || Math.floor(resp.status / 100) === 5) {    
                    try {
                        const data = await resp.json();
                        setError(data.message);
                        return;
                    } catch (err) {
                        console.log("Can't parse the error response: " + err);
                        throw resp;
                    }
                } else {
                    const data = await resp.json();
                    dispatch({type: 'init', payload: data});
                }
            } catch (err) {
                setErrorFromException(err);
            } finally {
                setLoading(false);
            }
        })().catch((err) => {
            setError(err);
        });
    }, [getApi, id]);

    const changeTitle = useCallback(
        (newTitleValue) => {
            dispatch({
                type: 'title',
                payload: newTitleValue
            });
        },
        [dispatch]
    )

    const changeDescription = useCallback(
        (newDescriptionValue) => {
            dispatch({
                type: 'description',
                payload: newDescriptionValue
            });
        },
        [dispatch]
    )

    const chnageBlueprintSelected = useCallback(
        (id, selected) => {
            dispatch({
                type: 'blueprint-selected',
                payload: {
                    id: id,
                    selected: selected
                }
            })
        },
        [dispatch]
    )

    useEffect(() => {
        console.log("Processed images");
        const processedIds = new Set();
        const api = getApi();
        if(!api) return;

        const timer = setInterval(() => {
            api.get(`/blueprints/${id}/processed`).then((resp) => {
                resp.json().then((data) => {
                    data.forEach((payload) => {
                        const id = payload.id;
                        if(!processedIds.has(id)) {
                            dispatch({
                                type: 'blueprint-processed',
                                payload
                            });
                            processedIds.add(id);
                        }
                    });
                });
            });            
        }, 1000);
        return () => clearInterval(timer);
    }, [dispatch, getApi, id])

    const BluerpintsFileInfo = useCallback(
        ({
            id,
            title,
            description,
            creator,
            lastupdator,
            published,
            blueprints,
            created,
            updated,
        }) => (<Form>
                <Form.Group className="mb-3" controlId="blueprintsId">
                    <Form.Label>Id</Form.Label>
                    <Form.Control type="text" value={id} disabled />
                </Form.Group>
                <Form.Group className="mb-3" controlId="blueprintsTitle">
                    <Form.Label >Title *</Form.Label>
                    <Form.Control maxLength={40} onChange={(it) => changeTitle(it.target.value)} required value={title} type="text" placeholder="Enter title" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="blueprintsDescription">
                    <Form.Label>Description</Form.Label>
                    <Form.Control maxLength={1000} onChange={(it) => changeDescription(it.target.value)} value={description} type="text" placeholder="Enter description" />
                </Form.Group>
                <Row><h4>Select which blueprints to include:</h4></Row>
                <Row>{blueprints && blueprints.map((it, index) => <BlueprintCheckbox onSelect={chnageBlueprintSelected} key={index} {...it} />)}</Row>
            </Form>),
        [changeTitle, changeDescription, chnageBlueprintSelected]
    )

    const save = useCallback(
        async () => {
            setLoading(true);
            const api = getApi();
            if(!api) {
                setError("API client is still initializing");
                setLoading(false);
                return;
            }

            try {
                info.published = true;
                const response = await api.put(`/blueprints`, info);
                if(Math.floor(response.status / 100) === 4 || Math.floor(response.status / 100) === 5) {
                    try {
                        const result = await response.json();
                        setError(result.message || response.statusText || `Error ${response.status}`);
                        return;
                    } catch (err) {
                        setError(`Can't save blueprints: ${response.statusText || response.status}`);
                    }
                } else {
                    const result = await response.json();
                    navigate(`/blueprints/${result.id}?profile=true`);                    
                }
            } catch (error) {       
                setErrorFromException(error);         
            } finally {
                setLoading(false);
            }
        },
        [getApi, info, navigate]
    );

    return (
        <Container style={{minHeight: '80vh'}}>
            <Row>
                <Col>
                    <h2>{(info && info.published && "Edit your Blueprints") || "Publish your blueprints"}</h2>
                </Col>
                <Col xs={1}>
                    <Button onClick={save} disabled={loading} variant="primary">
                        {(info && info.published && "Save") || "Publish"}
                    </Button>
                </Col>
            </Row>
            {error && <Row><Col><p style={{color: 'red'}}>{error}</p></Col></Row>}
            <Row>
                <Col>
                    {
                        (!loading && info && <BluerpintsFileInfo {...info} />) ||
                        (loading && <div className="app-saving-blueprints">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Saving...</span>
                            </Spinner>
                        </div>)
                    }
                </Col>
            </Row>
            <Row className='justify-content-end'>
                <Col xs={1}>
                    <Button onClick={save} disabled={loading} variant="primary">
                        {(info && info.published && "Save") || "Publish"}
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}



function BlueprintCheckbox({id, title, processed, selected, image, onSelect = (id, selected) => {}}) {
    return (
        <Col  onClick={() => onSelect(id, !selected)} style={{margin: '1rem', cursor: 'pointer'}} xs={3}>
            <BlueprintImage image={image} processed={processed} />
            <Form.Group className="checkbox-group" >
                <Form.Label>{title}</Form.Label>
                <Form.Check onChange={(it) => onSelect(id, !selected)} checked={selected}  />
            </Form.Group>
        </Col>
    )
}

function infoReducer(state, action) {
    switch (action.type) {
        case 'init':
            return {
                ...action.payload
            }
        case 'title':
            return {
                ...state, 
                title: action.payload
            };
        case 'description':
            return {
                ...state,
                description: action.payload
            };
        case 'blueprint-selected':
            return {
                ...state,
                blueprints: state.blueprints.map((it) => {
                    if (it.id === action.payload.id) {
                        return {
                            ...it,
                            selected: action.payload.selected
                        }
                    }
                    return it;
                })
            };
        case 'blueprint-processed':
            return {
                ...state,
                blueprints: state.blueprints.map((it) => {
                    if (it.id === action.payload.id) {
                        return {
                            ...it,
                            image: action.payload.image,
                            processed: true
                        }
                    } else {
                        return it;
                    }
                })
            };
        default:
            return state;
    }
}
