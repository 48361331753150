import './Cards.css';
import BlueprintsCard from './BlueprintsCard';
import {
    Container,
    Row,
    ButtonToolbar,
}  from 'react-bootstrap';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import PageButtons from './PageButtons';
import { useOpenApi, checkResult } from '../../hooks/api';

export async function useLoader({ request }) {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const api = useOpenApi();
  const result = await api.get("/blueprints/public?page=" + (page-1) + "&size=10")
  checkResult(result);
  return await result.json();
}

export default function Cards() {
    const {
      content,
      totalPages,
      numberOfElements,
    } = useLoaderData();
    
    const [searchParams, setSearchParams] = useSearchParams();
    const page = +searchParams.get('page') || 1;
    const setPage = p => setSearchParams({page: p});

    if(numberOfElements === 0) {
      return <div style={
        {
          minHeight: '80vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }
      }>
          <p>No blueprints have been uploaded yet. Be the first!</p>
      </div>
    }

    return (
        <Container style={{minHeight: '80vh'}}>
            <Row>
                <ButtonToolbar className='justify-content-between'>
                  {/* <InputGroup>
                      <Form.Control
                      type="text"
                      placeholder="Input group example"
                      aria-label="Input group example"
                      aria-describedby="btnGroupAddon"
                      />
                      <Button id="btnGroupAddon">Search</Button>
                  </InputGroup> */}
                  {totalPages > 1 && (
                    <PageButtons currentPage={page} totalPages={totalPages} onPageUpdate={setPage}/>
                  )}
                </ButtonToolbar>
            </Row>
            <div className='grid-app'>
                {
                  content && 
                  content.map((blueprint) => (
                    <BlueprintsCard key={blueprint.id} {...blueprint} />
                  ))
                }
            </div>
            <Row>
                <ButtonToolbar className='justify-content-end'>
                  {totalPages > 1 && (
                    <PageButtons currentPage={page} totalPages={totalPages} onPageUpdate={setPage}/>
                  )}
                </ButtonToolbar>
            </Row>
        </Container>
    );
}